
@import '~antd/dist/antd.css';



@font-face {
  font-family: "Baloo";
  src:  url("./Fonts/Barlow-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }


h4,
h1,
h2,
li,
h3,
h5,
p,
h6,

{
font-family: 'Baloo', sans-serif;
  color: #222;

}

table {
  width: 100%;
  max-width: 800px;
}
table td {
  width: 33.33%;
  vertical-align: top;
}
fieldset {
  min-height: 100%;
  border: 1px solid silver;
  padding: 10px;
}
button {
  padding: 5px;
  margin: 5px;
}
video {
  width: 100%;
}
span {
  font-family: monospace;
}


.header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #222;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  display: block;
    color: #222;
    font-size: 18;
  padding: 20px 20px;
  border-right: 1px solid #fff;
  text-decoration: none;
}


.header li a:hover,
.header .menu-btn:hover {
  background-color: #ccc;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

.App {
  text-align: center;
  color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
body{

  color: "#000";
  font-family: 'Baloo', sans-serif;
}

.mapContainer {





 height: "100%";
}


.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  padding: 10px;
  border-radius: 30px;
  color: #222;
  height: 70px;
  width: 180px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content h3 {

  color: #000;
  margin: 0;
  display: block;

  border-radius: 3px 3px 0 0;
  font-weight: 700;

}

.mapboxgl-popup-content i {

  font-size: 40px;
  color: "#fff";
  margin-top: 0px;

  margin-left: -1px;


}

.dot {
  height: 55px;
  width: 55px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  padding: 10px;
  position: absolute;
  right: 10px;
    margin-left: 30px;
    margin-top: -30px;

padding-left: 52  ;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
}

.bg-green-dark{background-color:#269561!important}
.bg-green{background-color:#33c377!important}
.bg-green-light{background-color:#afdec5!important}
.bg-green-faint{background-color:#e8f5ee!important}
.myrrd{

  border-color: #222;
  border-radius: 15px;
  height: 30px;
  width: 30px;
}

.mapboxgl-popup-content h4 {
  margin: 0;
  display: block;
  padding: 10px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}
.mapboxgl-popup {
max-width: 500px;
font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.myButton {
	background-color:#44c767;
	border-radius:28px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Times New Roman;
	font-size:14px;
	padding:10px 46px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627;
}
.myButton:hover {
	background-color:#5cbf2a;
}
.myButton:active {
	position:relative;
	top:1px;
}

.legend {
background-color: none;
border-radius: 3px;
bottom: 30px;

font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
padding: 10px;
position: absolute;
right: 10px;
z-index: 1;
}



.legend2 {
  background-color: #fff;
border-radius: 3px;
bottom: 1px;

width: 100%;
padding: 10px;
position: absolute;
right: 1px;
z-index: 1;
}
.legend h4 {
margin: 0 0 10px;
}

.legend div span {
border-radius: 20px;
display: inline-block;
height: 7px;
margin-right: 5px;
width: 30px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom styles */

.local-container{
  position: absolute;
  left: 0;
  top: 0;
  margin: 10px;
  text-align: left;
  width: 300px;
}

.local-container video{
  background: black;
}

.local-container button{
  display: inline-block;
  width: 50%;
  font-size: 18px;
  padding: 5px;
}

.remote-container{
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  text-align: right;
  width: 300px;
}

.remote-container video{
  background: black;
}

.remote-container button{
  display: inline-block;
  width: 100%;
  font-size: 18px;
  padding: 5px;
}

.message-container{
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 10px;
  width: 300px;
}

.message-container button{
  display: inline-block;
  width: 100%;
  font-size: 18px;
  padding: 5px;
}
